import { parseISO } from "date-fns";
import { format, formatInTimeZone } from "date-fns-tz";
const INPUT_VALUE_FORMAT = 'yyyy-MM-dd hh:mm';
const REQUEST_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss xxx';
const DATE_FORMAT = 'M/D/yyyy';
const DATE_FORMAT_ALT = 'MMM d, y';
const DEFAULT_LOCAL_FORMAT = "M/d/y - h:mm aa";
const DEFAULT_LOCAL_FORMAT_WITH_TZ = "M/d/y - h:mm aa (z)";
const DEADLINE_FORMAT = "h:mm aa on MMM d, y";
/** date-fns formating doc: https://date-fns.org/v3.6.0/docs/format
 */
export const guessCurrentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
/**
 * Takes local datetime and formats it in api friendly formatting (UTC)
 * @param {string} dateString
 * @param {string | null} defaultValue
 * @returns converted datetime in the REQUEST_FORMAT: `yyyy-MM-dd'T'kk:mm:ss`
 */
export const DateToRequestFormat = (datetimeLocal, defaultValue = null) => {
    if (datetimeLocal == "")
        return defaultValue;
    const datetimeUtc = DateTimeToUTC(datetimeLocal, REQUEST_FORMAT);
    if (datetimeUtc == "")
        return null;
    return datetimeUtc;
};
/**
 * Queries all HTML Elements with class `datetimeToLocale` and updates the value with local datetime
 *  based on UTC datetime in attr `data-datetime-utc` and format: `data-datetime-format`
 */
export function LoadAllDateTimeInLocal(scope = null) {
    let datetimeList;
    if (scope == null) {
        datetimeList = Array.from(document.querySelectorAll('.datetimeToLocale'));
    }
    else {
        datetimeList = Array.from(scope.querySelectorAll('.datetimeToLocale'));
    }
    datetimeList.map(i => {
        var _a, _b;
        let defaultFormat = (i.tagName == "input" ? INPUT_VALUE_FORMAT : DEFAULT_LOCAL_FORMAT_WITH_TZ);
        const localDate = DateTimeToLocal((_a = i.dataset.datetimeUtc) !== null && _a !== void 0 ? _a : "", (_b = i.dataset.datetimeFormat) !== null && _b !== void 0 ? _b : defaultFormat);
        i.innerText = localDate;
    });
    return datetimeList;
}
/**
 * Inits all datetime-local type inputs in given scope to convert and update the value with local datetime based on UTC datetime in attr `data-datetime-utc`
 */
export function ReloadAllInputDateTimeToLocal(input = null, scope = null) {
    if (input != null) {
        SetInputDateToLocal(input);
        return [input];
    }
    else {
        let inputList;
        if (scope == null) {
            inputList = Array.from(document.querySelectorAll('input[type="datetime-local"]'));
        }
        else {
            inputList = Array.from(scope.querySelectorAll('input[type="datetime-local"]'));
        }
        inputList.map(i => {
            // const localDate = this.DateTimeToLocal(i.value);
            SetInputDateToLocal(i);
        });
        return inputList;
    }
}
export function SetInputDateToLocal(input) {
    if (input == null)
        return;
    // get UTC from attr and set value
    if (input.value == "") {
        input.value = DateTimeToLocal(input.dataset.dateTimeUtc);
    }
    // get local from value and update attr
    else {
        const localDate = DateTimeToUTC(input.value);
        input.dataset.dateTimeUtc = localDate;
    }
}
/**
 * Same as ReloadAllInputDateTimeToLocal but for one-offs
 * returns Date object
 * @param datetimeUTC
 * @param format - default format is `DEFAULT_LOCAL_FORMAT: `M/d/Y - h:mm aa`
 * @returns
 */
export function DateTimeToLocal(datetimeUTC, oFormat = null) {
    if (datetimeUTC == null)
        return "";
    if (datetimeUTC.indexOf("+") == -1)
        datetimeUTC += "+0";
    return format(parseISO(datetimeUTC), oFormat !== null && oFormat !== void 0 ? oFormat : DEFAULT_LOCAL_FORMAT_WITH_TZ);
}
/**
 * measures and returns the difference in UTC offset between 2 dates
 * @param targetDate - Date to compare with to calculate TZ diff
 * @param sourceDate - nullable. Defaults to DateTime.Now
 * @returns int value in minutes denoting the target offset from today.
 *          if today is UTC-7 and targetDate is UTC-8, this will return 60[minutes]
 *          if today is UTC-8 and targetDate is UTC-7, this will return -60[minutes]
 */
export function GetOffsetDeltaFromTargetDate(targetDate, sourceDate) {
    sourceDate !== null && sourceDate !== void 0 ? sourceDate : (sourceDate = new Date()); // Dateify undefined input
    sourceDate = new Date(sourceDate); // to Dateify string input
    targetDate = new Date(targetDate); // to Dateify string input
    //target - local
    return targetDate.getTimezoneOffset() - sourceDate.getTimezoneOffset();
}
export function DateTimeToUTC(datetimeLocal, oFormat = null) {
    if (datetimeLocal == null)
        return "";
    // sanitize datetimeLocal string
    // datetimeLocal = datetimeLocal.replace(" UTC", "");
    // if (datetimeLocal.indexOf(" +", 4) > 0)
    //     datetimeLocal = datetimeLocal.substring(0, datetimeLocal.indexOf(" +"));
    const parsedTime = parseISO(datetimeLocal);
    return formatInTimeZone(parsedTime, "UTC", oFormat !== null && oFormat !== void 0 ? oFormat : DATETIME_FORMAT);
}
export default DateToRequestFormat;
